// REACT AND GATSBY IMPORTS
import React from 'react';
// STYLING
// CONTAINERS
// import BannerSection from '../../containers/SaasClassic/Banner'
// COMPONENTS (landing-gatsby)
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
// COMMON COMPONENTS
import { AllJobs } from 'common/src/components/JobList/AllJobs' // Widget-ify later?
import { PodcastWidget } from 'common/src/components/UserAccountWidgets/PodcastWidget'
import WidgetContainer from 'reusecore/src/elements/Widget/WidgetContainer'

// User homepage
const UsersHomePage = () => {
  return (
    <UsersLayout seo={{ title: 'Home' }}>
      <WidgetContainer>
        <PodcastWidget />
        <AllJobs heading="All Transcription Jobs" redirectPath="users/job" />
      </WidgetContainer>
    </UsersLayout>
  );
};

export default UsersHomePage;
