// REACT AND GATSBY
import React, { useState, useEffect } from 'react';
// ELEMENTS AND COMPONENTS
import WidgetLayout from 'reusecore/src/elements/Widget/WidgetLayout';
import PodcastTile from 'common/src/components/InfoTile/podcastTile'
// STYLING
// LOGIC AND HANDLERS
import { getUser } from '../../../../api/UserLoginAPI'
// NPM MODULES

// Podcast Widget
export const PodcastWidget = () => {
  const [ user, updateUser ] = useState(null)
  // Fetch user information on load
  useEffect( () => {
    updateUser( getUser() )
  }, [])

  if(user){
    return (
      <WidgetLayout 
        // title={user.podcast.title_original}
        width={1}
      >
        <PodcastTile
          tileStyle='large'
          podcast={user.podcast}
          redirectObj={{
            path: '',
            queryParams: null,
          }}
        />
      </WidgetLayout>
    );
  } else {
    return (<></>)
  }
};
